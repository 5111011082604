import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import Banner from '../images/new/banners/atividades-e-projetos.png'
import Cta from '../images/new/banners/atividades-e-projetos-2.png'
import Rocket from '../images/new/banners/border.png'
import Ellipse from '../images/new/icons/ellipse2.svg'
import Cloud from '../images/new/icons/cloud-2.svg'
import Bird from '../images/new/icons/bird-2.svg'

import { 
  section,
  HeaderBanner,
  container,
  content,
  columns,
  column, 
  desktop,
  mobile,
  box1,
  box2
} from '../components/styles/Base.styles'

const Estrutura = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Atividades e Projetos | Um reino de aprendizado e conhecimento" />
    <NavBar />
    <div css={HeaderBanner}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div>
    <div css={[section, content]} style={{ paddingBottom: 0 }}>
      <div css={container}>
        <h1>Atividades e Projetos</h1>
        <p>Após o horário regular, nossos alunos podem se dedicar a aulas extras voltadas a artes e esportes, distribuídas entre todas as faixas etárias. As atividades contam, em geral, com a ajuda de parceiros externos, dentro de nossa estrutura.</p>
        <h2>Programa Contraturno</h2>
        <p>As crianças que ficam no horário contrário ao de ensino regular recebem atividades recreativas e esportivas. Dentre elas estão: xadrez, capoeira, futebol e dança. Cada grupo tem uma carga horária dependendo da idade. Além disso, as crianças têm atividades pedagógicas e projetos específicos que são desenvolvidos no programa.</p>
        <h2>Atividades Extracurriculares</h2>
        <p>Possuímos aulas de judô e ballet para alunos a partir de 2 anos. As atividades acontecem ao final de cada turma.</p>
        <img src={Cta} alt='' style={{ marginTop: 0, width: '100%' }} />
        <h2>Princípios Pedagógicos</h2>
        <p>O projeto Educacional da Kingdom School, etapa do Ensino Fundamental, promove a continuidade do trabalho que se inicia na Kingdom Kids, na etapa da Educação Infantil. Os ciclos se baseiam nos nossos três pilares: estimulação, segurança e afeto. Para formar a base da estimulação, consideramos alguns importantes pilares desse processo e na busca pelos métodos mais compatíveis com nossa filosofia.</p>
        <p>Utilizamos:</p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div css={box1} style={{ marginBottom: 48 }}>
            <ul>
              <li>
                <img src={Ellipse} alt='' style={{ width: 'auto' }} />
                <p>Método fônico de alfabetização;</p>
              </li>
              <li>
                <img src={Ellipse} alt='' style={{ width: 'auto' }} />
                <p>Método Singapura para a matemática;</p>
              </li>
              <li>
                <img src={Ellipse} alt='' style={{ width: 'auto' }} />
                <p>Inglês – Programa de Fluência em Inglês – Beyond;</p>
              </li>
              <li>
                <img src={Ellipse} alt='' style={{ width: 'auto' }} />
                <p>Salas de aula ambiente.</p>
              </li>
            </ul>
          </div>
          <div css={desktop}><img src={Cloud} style={{ marginTop: 0, marginLeft: 120 }} /></div>
        </div>
        <p>Programa de Fluência em Inglês – Beyond: <br />
        Nossos alunos fazem parte da Beyond, um programa de fluência em Inglês dentro da carga horária!</p>
        <p>Diferenciais:</p>
        <div css={box2} style={{ marginBottom: 48 }}>
          <ul>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Metodologias Ativas:</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Projetos interdisciplinares;</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Estudantes Protagonistas;</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Nivelamento de acordo com o CEFR - Quadro Comum Europeu a partir do 3o ano dos Anos Iniciais;</p>
            </li>
          </ul>
        </div>
        <p>Projetos</p>
        <div style={{ textAlign: 'right' }}><img src={Bird} style={{ marginBottom: 0, width: 'auto' }}/></div>
        <div css={box2} style={{ marginBottom: 112 }}>
          <ul>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>English Camp e English Immersion;</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>English Drama Festival;</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Eventos Culturais;</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Parceria com as melhores editoras;</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Preparatório para olimpíadas;</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Preparatório para exames internacionais (SAT, TOEFL, IELTS e Cambridge);</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Debates.</p>
            </li>
          </ul>
        </div>
        <div css={columns}>
          <div css={column} style={{ display: 'flex', alignItems: 'end' }}><img css={desktop} src={Rocket} alt=''/></div>
          <div css={column}>
            
            <div css={mobile}>
              <img src={Rocket} alt='' style={{ width: 'auto', marginBottom: 0, display: 'flex', justifyContent: 'center' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default Estrutura
